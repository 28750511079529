import { ILiveTranslations } from '.';

const live: ILiveTranslations = {
  awaiting_payment: 'No pagado',
  buy_ticket: 'Comprar billete',
  checkin_success: 'Registrado con éxito',
  entered: 'Entrado',
  exited: 'Salido',
  invoicing: 'Facturación',
  new_registration: 'Nuevo registro',
  no_email_label: 'Sin correo electrónico',
  no_results: 'No se han encontrado resultados',
  payment_confirmation_popup: '¿Desea confirmar este pago?',
  paymnet_confirmation_success: 'Pago confirmado con éxito',
  payment_information_title: 'Información del pago',
  print_label: 'Imprimir etiqueta',
  registered: 'Registrado',
  pending_success: 'Estado cambiado a pendiente',
  pre_registration_success: 'Actuado con éxito el prerregistro de este visitante',
  registration_success: 'Registrado con éxito',
  session_registrations: 'Registros de sesión',
  set_pending: 'Establecer estado a pendiente',
  set_registered: 'Establece el estado a registrado',
  short_id_error: 'No se ha podido actualizar el short ID; compruebe que existe dentro del evento',
  subtitle: 'Utilidades de eventos en tiempo real',
  subtitle_empty: 'Por favor, busque un visitante o cree uno nuevo',
  visitor_already_exists: 'Un visitante con este email ya ha sido registrado para este evento',
  visitor_label: 'Etiqueta del visitante',
  visitor_not_found: 'No se ha podido encontrar al visitante',
  visitor_type: 'Tipo de visitante',
  ticket_type: 'Tipo de entrada',
  ticket_type_help: 'Esto no incluye las entradas de grupo',
  title_empty: 'Aquí se mostrará la información del visitante',
};

export default live;
