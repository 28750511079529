import { ILiveTranslations } from '.';

const live: ILiveTranslations = {
  awaiting_payment: 'Not paid',
  buy_ticket: 'Buy ticket',
  checkin_success: 'Successfully checked in',
  entered: 'Entered',
  exited: 'Exited',
  invoicing: 'Invoicing',
  new_registration: 'New registration',
  no_email_label: 'With no email',
  no_results: 'Could not find results',
  payment_confirmation_popup: 'Do you wish to proceed with confirming this payment?',
  paymnet_confirmation_success: 'Payment confirmed successfully.',
  payment_information_title: 'Payment information',
  print_label: 'Print label',
  registered: 'Registered',
  pending_success: 'Status changed to pending',
  pre_registration_success: "Successfully actioned this visitor's pre registration",
  registration_success: 'Registered successfully',
  session_registrations: 'Session registrations',
  set_pending: 'Set status to pending',
  set_registered: 'Set status to registered',
  short_id_error: 'Short ID could not be updated; check that it exists within the event.',
  subtitle: 'Real time event utilities',
  subtitle_empty: 'Please search for a visitor or create a new one',
  visitor_already_exists: 'A visitor with this email has already been registered for this event.',
  visitor_label: 'Visitor label',
  visitor_not_found: 'Visitor could not be found',
  visitor_type: 'Visitor type',
  title_empty: 'Your visitor information will be displayed here!',
  ticket_type: 'Ticket type',
  ticket_type_help: 'This does not include group tickets',
};

export default live;
