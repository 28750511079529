import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  events: 'Eventos',
  exhibitors: 'Expositores',
  exhibitor: 'Empresa',
  edit: 'Editar',
  add_spacer: 'Añadir ficha',
  add_stage: 'Añadir etapa',
  stages_message: 'Estos estarán disponibles en sus formularios de sesión',
  edit_checkin: 'Editar check-in',
  remove: 'Quitar',
  create: 'Crear',
  delete: 'Eliminar',
  name: 'Nombre',
  first_last_name: 'Nombre y apellido',
  name_placeholder: 'Introduzca su nombre',
  email: 'Correo electrónico',
  invalid_email: 'Este correo electrónico no es válido',
  email_already_associated:
    'El correo electrónico introducido ya está asociado con otra suscripción',
  description: 'Descripción',
  category: 'Categoría',
  categories: 'Categorías',
  model: 'Modelo',
  save: 'Guardar',
  download: 'Descargar',
  upload: 'Subir',
  change: 'Cambiar',
  apply: 'Aplicar',
  items: 'Artículos',
  empty_field_not_allowed: 'Este campo no puede estar vacío',
  next: 'Siguiente',
  previous: 'Anterior',
  close: 'Cerrar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  search: 'Buscar',
  mandatory_field: 'Este campo es requerido.',
  name_in_use: 'Este nombre ya esta en uso.',
  crop: 'Cortar',
  published: 'Publicado',
  not_published: 'No publicado',
  all: 'Todas',
  empty_list: 'Datos no disponibles',
  user_chat_failed: 'No se pudo abrir el chat con esta usuario',
  activity_center: 'Centro de actividades',
  no_recent_activity: 'No hay actividad reciente',
  unread_messages: 'Mensajes no leídos',
  reply: 'Responder',
  session_invitations: 'Invitaciones sin respuesta',
  accept: 'Aceptar',
  refuse: 'Rechazar',
  in_progress: 'En progreso',
  recent_files: 'Archivos recientes',
  open_conversations: 'Conversaciones abiertas',
  all_fields_required: 'Todos los campos requeridos',
  something_went_wrong:
    'Algo ha ido mal. <br>Si el problema persiste, ponte en <a href="mailto:support@beamian.com">contacto con nosotros.</a>',
  already_exists: 'Ya existe',
  profile: 'Perfil',
  filter_by_status: 'Filtrar por estado',
  open_meeting: 'Entrar a la reunión',
  visitors: 'Visitante',
  saved: 'Guardado',
  back: 'Volver',
  session_full: 'La sesión está llena',
  file_not_image: 'Este archivo no es una imagen',
  new_export: 'Exportación nueva',
  state: 'Estado',
  details: 'Detalles',
  see_more: 'Ver más',
  more: 'Más',
  manage: 'Gestionar',
  see_as_visitor: 'Ver como asistente',
  see_as_exhibitor: 'Ver como expositor',
  manage_visitors: 'Administrar visitante',
  manage_exhibitors: 'Administrar expositor',
  manage_event: 'Administrar evento',
  registered: 'registrado',
  refused: 'rechazado',
  pending: 'pendiente',
  session_manager: 'Gestor de sesión',
  export_questions: 'Exportar preguntas',
  my_mod_questions: 'Últimas preguntas',
  mod_questions: 'Preguntas para moderación',
  pending_plural: 'Pendientes',
  read: 'Leídos',
  mark_as_read: 'Marcar como leído',
  accepted: 'acceptado',
  favorites: 'Favoritos',
  add_to_favorites: 'Agregar a los favoritos',
  remove_from_favorites: 'Quitar de favoritos',
  contact_request: 'Solicitud de contacto',
  invited: 'Invitado/a',
  note: 'Nota',
  discard: 'Descarte',
  no_comment: 'Sin comentario',
  click_here: 'Haga clic aquí',
  sessions: 'Sesiones',
  event_sessions: 'Sesiones de eventos',
  exhibitor_sessions: 'Sesiones de expositor',
  networking: 'Redes',
  total: 'Total',
  update_success: 'Información actualizada con éxito',
  agree_to_terms:
    'Acepto los <a href="https://beamian.com/terms/" target="_blank"><b>términos y condiciones de beamian</b></a>',
  add_to_calendar: 'Añadir a su calendario',
  event_already_started: 'Este evento ya ha comenzado',
  countdown: 'Cuenta atrás',
  please_check_answers: 'Por favor, compruebe sus respuestas',
  sort_by: 'Ordenar por',
  order: 'Orden',
  date: 'Fecha',
  ascending: 'Ascendente',
  descending: 'Descendente',
  no_data_title: 'No hay nada que ver aquí!',
  no_data_subtitle: 'Por favor, vuelva más tarde.',
  exhibitor_interactions: 'Interacciones del expositor',
  interactions: 'Aplicaciones espontáneas',
  product_interactions: 'Solicitud de empleo',
  sessions_subtitle: 'Últimos datos sobre números de sesiones y estadísticas',
  online_registrations: 'Registros para acceso en línea',
  in_person_registrations: 'Registros para acceso en persona',
  contacts: 'Contactos',
  created: 'Creado',
  seeing_as_visitor: 'Ver evento como visitante',
  total_sessions: 'Total de sesiones',
  ticket_types: 'Tipo de billete',
  image: 'Imagen',
  add: 'Acrescentar',
  tickets: 'Billetes',
  sold: 'Vendido',
  price: 'Precio',
  select_exhibitor: 'Seleccionar expositor',
  code: 'Código',
  type: 'Tipo',
  discount: 'Descuento',
  status: 'Visibilidad',
  from: 'De',
  to: 'Hasta',
  filter: 'Filtro',
  option: 'Opción',
  session_privacy: 'Privacidad en sesiones',
  private: 'Privado',
  public: 'Público',
  my_addons: ' Mis add-ons/Extras',
  see_addons_bought: 'Ver los add-ons/extras que has comprado',
  form: 'Formulario',
  checkout: 'Tramitar pedido',
  product_status: 'Estado',
  price_includes_tax: 'Precio con impuestos',
  filter_visitor_contacts: 'Filtrar contactos de visitantes',
  filter_exhibitors: 'Filtrar expositores',
  check_sources: 'Filtrar por origen de interacción y respuestas del visitante',
  filter_visitor_multiple_select:
    'Al seleccionar varios elementos, los contactos se filtrarán por todas las opciones seleccionadas',
  content_not_allowed: 'No tiene permiso para ver este contenido',
  fill_payment_information: 'Llenar la información de pago',
  only_letters_numbers_hyphen_underscore:
    'Solo se permiten letras, números, guiones y guiones bajos',
  registration_closed: 'El registro para :event_name ya está cerrado.',
  return_home: 'Go back home',
  character_limit_1: 'limited to',
  character_limit_2: 'characters',
  booklet_sample: 'Booklet sample',
  invite_managers: 'Invitar/destituir gestores',
  scan_for_interactions: 'Buscar interacciones',
  invite_managers_cta: 'Invitar gestores',
  grant_permissions: 'Permisos de gestión',
  grant_permissions_error: 'Error al cambiar los permisos',
  invitation_send_error: 'Error al enviar una invitación',
  permissions_changed: 'Permisos actualizados correctamente',
  pre_registered: 'En preinscripción',
  evaluate_session: 'Evaluar sesión',
  session_evaluation_title: 'Evaluación de la sesión',
  confirm_vote: '¿Está seguro de que quiere confirmar su voto?',
  vote_already_submitted: 'Su respuesta a esta pregunta ya ha sido enviada',
  vote_cannot_be_update: 'Su voto no puede ser actualizado después de la presentación',
  evaluate_event: 'Evaluar el evento',
  event_evaluation_title: 'Evento Evaluación',
  sold_out: 'Agotado',
};

export default common;
