import { ILiveTranslations } from '.';

const live: ILiveTranslations = {
  awaiting_payment: 'Nicht bezahlt',
  buy_ticket: 'Ticket kaufen',
  checkin_success: 'Erfolgreich eingecheckt',
  entered: 'Eingereist',
  exited: 'Beendet',
  invoicing: 'Fakturierung',
  new_registration: 'Neue Registrierung',
  no_email_label: 'Ohne E-Mail',
  no_results: 'Konnte keine Ergebnisse finden',
  payment_confirmation_popup: 'Möchten Sie mit der Bestätigung dieser Zahlung fortfahren?',
  paymnet_confirmation_success: 'Zahlung erfolgreich bestätigt.',
  payment_information_title: 'Zahlungsinformationen',
  print_label: 'Etikett drucken',
  registered: 'Registriert',
  pending_success: 'Status auf ausstehend geändert',
  pre_registration_success: 'Die Vorregistrierung dieses Besuchers wurde erfolgreich durchgeführt',
  registration_success: 'Erfolgreich registriert',
  session_registrations: 'Sitzungsregistrierungen',
  set_pending: 'Status auf schwebend setzen',
  set_registered: 'Status auf registriert setzen',
  short_id_error:
    'Die Kurz-ID konnte nicht aktualisiert werden; prüfen Sie, ob sie im Ereignis vorhanden ist',
  subtitle: 'Echtzeit-Ereignis-Hilfsmittel',
  subtitle_empty: 'Bitte suchen Sie nach einem Besucher oder erstellen Sie einen neuen',
  visitor_already_exists:
    'Ein Besucher mit dieser E-Mail-Adresse wurde bereits für diese Veranstaltung registriert.',
  visitor_label: 'Bezeichnung des Besuchers',
  visitor_not_found: 'Besucher konnte nicht gefunden werden',
  visitor_type: 'Besuchertyp',
  ticket_type: 'Ticket-Typ',
  ticket_type_help: 'Dies schließt keine Gruppentickets ein',
  title_empty: 'Hier werden Ihre Besucherinformationen angezeigt!',
};

export default live;
