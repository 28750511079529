import { ILiveTranslations } from '.';

const live: ILiveTranslations = {
  awaiting_payment: 'Não pago',
  buy_ticket: 'Comprar bilhete',
  checkin_success: 'Check-in feito com sucesso',
  entered: 'Entrou',
  exited: 'Saiud',
  invoicing: 'Faturação',
  new_registration: 'Novo registo',
  no_email_label: 'Sem email',
  no_results: 'Não foi possível obter resultados',
  payment_confirmation_popup: 'Deseja prosseguir com a confirmação deste pagamento?',
  paymnet_confirmation_success: 'Payment confirmed successfully.',
  payment_information_title: 'Informação de pagamento',
  print_label: 'Imprimir etiqueta',
  registered: 'Registado',
  pending_success: 'Estado alterado para pendente',
  pre_registration_success: 'Pré-registo de visitante acionado com sucesso',
  registration_success: 'Registado com sucesso',
  session_registrations: 'Registo em sessões',
  set_pending: 'Alterar estado para pendente',
  set_registered: 'Alterar estado para registado',
  short_id_error: 'O short ID não pôde ser atualizado; verifique que existe no evento.',
  subtitle: 'Ferramentas de apoio a evento em tempo real',
  subtitle_empty: 'Pesquise pelos dados de um visitante ou crie um novo',
  visitor_already_exists: 'Um visitante com este endereço de e-mail já se registou neste evento.',
  visitor_label: 'Etiqueta de visitante',
  visitor_not_found: 'Não foi possível encontrar o visitante',
  visitor_type: 'Tipo de visitante',
  ticket_type: 'Tipo de bilhete',
  ticket_type_help: 'Bilhetes de grupo não incluídos',
  title_empty: 'A sua informação sobre o visitante aparecerá aqui!',
};

export default live;
